import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Row, Col, Container } from 'react-bootstrap';
import AgeVerification from '../components/AgeVerification/AgeVerification';
import { CookiesProvider, useCookies } from 'react-cookie';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout/Layout';

const AboutTheCompanyPage = () => {
  const { t } = useTranslation();
  const [showAgeVerification, setShowAgeVerification] = useState(true);
  const [cookies, setCookie] = useCookies(['verified']);

  const handleAgeVerification = () => {
    setCookie('verified', true, { path: '/' });
    setShowAgeVerification(false);
  };

  const handleAgeVerificationDeclined = () => {
    window.location.href = 'https://google.com';
  };
  return (
    <Layout>
      <Container>
        <CookiesProvider>
          <div className={`d-none ${showAgeVerification ? 'd-block' : 'd-none'}`}>
            {showAgeVerification && !cookies.verified && (
              <AgeVerification
                id="ageVerification"
                handleAgeVerification={handleAgeVerification}
                handleAgeVerificationDeclined={handleAgeVerificationDeclined}
              />
            )}
          </div>
        </CookiesProvider>
        <Row id="page">
          <Col>
            <div className="main-content" style={{ margin: '2rem 0' }}>
              <h1 className="title main-title tex-uppercase main-title--blue">{t('about_page.title')}</h1>
              <br />
              <p dangerouslySetInnerHTML={{ __html: t('sections.section_1.description') }} />
              <br />
              <div>
                <h3>{t('about_page.general_data')}</h3>
                <br />
                <b>Name of the company:</b> OptiFilter International Private Limited Company <br />
                <br />
                <b>Seat of the company:</b> 1112 Budapest, Brassó utca 42, Hungary <br />
                <br />
                <b>Company number:</b> 01-10-141372 <br />
                <br />
                <b>Company form:</b> Share company <br />
                <br />
                <b>Community tax number:</b> HU25086012 <br />
                <br />
                <b>European Unique Identifier:</b> HUOCCSZ.02-10-060404 <br />
                <br />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AboutTheCompanyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
